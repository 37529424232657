body {
  background-color: #35654d;
}

.App {
  text-align: center;
  height: 100%;
  overflow-y: hidden;
  overflow-x: hidden;
}

/* --- FORMS --- */
.form-container {
  width: 400px;
  height: 300px;
  position: absolute;
  margin: auto;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.switch-label {
  margin-bottom: 15px;
}

.form-field {
  width: 100%;
  margin-bottom: 15px !important;
}

.form-button {
  width: 100%;
  border-radius: 15px !important;
}

/* --- PLAY AREA --- */
#GameArea {
  min-height: 100vh;
  overflow-y: hidden;
  overflow-x: hidden;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.board {
  width: 100%;
  height: 100%;
  position: absolute;
  padding-top: 20px;
}

.btn {
  margin: 5px;
}

.copy-btn {
  display: inline-flex;
  vertical-align: middle;
  margin: 0px 5px 4px 5px !important;
}

.inline-icon {
  margin-left: 5px;
}

.users-bar-container {
  display: flex;
  justify-content: center;

  ul {
    list-style-type: none;
    padding: 0;
    overflow: hidden;
  
    li {
      margin: 0 1em;
      float: left;
      font-size: 32px;
    }
  }
}

.hand {
  text-align: center;
  width: 100%;
  height: 250px;
  position: absolute;
  margin: auto;
  bottom: 10px;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.hand-inner {
  display: inherit;
}

/* --- PLAYING CARDS --- */
.card {
  width: 150px;
  height: 240px;
  cursor: pointer;
  float: left;
  transition: all .2s ease-in-out;
  position: relative;
  z-index: 15000;
  &:hover {
    transform: scale(1.15) translate(0, -15px);
    z-index: 15001;
  }
  perspective: 1000px;
  margin-right: 10px;
  transform: translate3d(0, 0, 0);
}

.card-small {
  width: 40px;
  height: 65px;
  cursor: default;
  float: left;
  transition: none;
  margin: 5px;
  margin-bottom: 10px;

  .card-inner > .card-back > img {
    width: 41px !important;
  }

  &:hover {
    transform: none;
  }
}

.card-inner {
  position: relative;
  width: 100%;
  height: 100%;
  border: 1px solid black;
  border-radius: 5px;
  background-color: rgb(245,245,245);
  transition: transform 0.6s;
  transform-style: preserve-3d;
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
}

.card-front, .card-back {
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

.card-flipped {
  transform: rotateY(180deg);
}

.card-img > img {
  margin-top: 85px;
  margin-bottom: 25px;
  margin-left: -20px;
  clear: both;
  width: 64px;
  height: 64px;
}

.card-val-top {
  float: left;
  margin-left: 5px;
  margin-top: 2px;
}

.card-val-bot {
  float: right;
  transform: rotate(180deg);
  margin-right: 5px;
  margin-bottom: 2px;
}

.centreOfBoard {
  position: absolute;
  left: 50%;
  transform: translate(-50%);
  -webkit-transform: translate(-50%);
}

.grid-container {
  position: fixed;
  margin: 0 auto;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 575px;
}

.centred {
  width: 400px;
  margin: 0 auto;
  margin-top: 10px;
}

.current-game {
  display: flex;
  align-items: center;
}

.current-game-txt {
  flex: 1;
}

.change-game-btn {
  flex: 0.6;
}
